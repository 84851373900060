<template>
  <div class="senior-setup">
    <el-form label-position="top" label-width="80px">
      <el-form-item label="审批同意时是否签字">
        <el-switch inactive-text="无需签字" active-text="需要签字" v-model="setup.sign"></el-switch>
        <div class="sign-tip">如果此处设置为 <b>需要签字</b>，则所有审批人“同意时” <b>必须签字</b></div>
      </el-form-item>
      <el-form-item label="设置摘要字段">
        <div class="sign-tip">选中的字段将会展示在审批列表上</div>
        <div class="sign-tip">
          <el-select v-model="formItem" placeholder="请选择分组" @change="formItemChange" clearable size="medium">
            <el-option
              v-for="(op, index) in formItems"
              :key="index"
              :label="op.title"
              :value="op.id"
            />
          </el-select>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { filterData } from "@/utils";

export default {
  name: "FormProSetting",
  computed: {
    setup() {
      return this.$store.state.design.settings;
    },
    digest() {
      return this.$store.state.design.digest;
    },
    design() {
      return this.$store.state.design
    },
    formItems() {
      return filterData(this.$store.state.design.formItems)
    }
  },
  data(){
    return{
      formItem: []
    }
  },
  watch: {
    digest: {
      handler(val){
        this.formItem = val.id
      },
      deep: true
    },
    formItems: {
      handler(val){
        this.formItemChange()
      },
      deep: true
    }
  },
  methods:{
    validate(){
      return []
    },
    formItemChange() {
      this.design.digest = this.formItems.filter(item => this.formItem.includes(item.id))[0] || {}
    }
  }
}
</script>

<style lang="less" scoped>
.senior-setup {
  overflow: auto;
  width: 600px;
  height: calc(100vh - 105px);
  background: #ffffff;
  margin: 10px auto 0;
  padding: 15px 20px;

  .sign-tip {
    color: #949495;
    font-size: small;
    margin-left: 20px;
  }
}
</style>
